// import { Parallax } from 'react-scroll-parallax';
import Styles from './Parallax.module.scss';

const LeftTriangle = () => {
  return (
    <div className={Styles.parallax}>
      <div className={Styles.group}>
        <div className={Styles.smallTriangle} />
        <div className={Styles.largeTriangle} />
      </div>
    </div>
  );
};

export default LeftTriangle;
